import React from 'react';
import Navbar from '../Nav';
import Experience from './Experience';

function Main() {
  const experiences = [
    {
      company: 'ABC Inc.',
      title: 'Software Engineer',
      duration: 'Jan 2022 - Present',
      responsibilities: [
        'Develop and maintain web applications',
        'Collaborate with cross-functional teams',
        'Write clean and scalable code',
      ],
    },
    {
      company: 'XYZ Corp.',
      title: 'Intern',
      duration: 'May 2021 - Aug 2021',
      responsibilities: [
        'Assisted with testing and debugging code',
        'Participated in team meetings and code reviews',
        'Learned about software development best practices',
      ],
    },
  ];

  return (
    // <div>
    //   <Navbar />
    //   <div className='flex justify-center text-white pt-10'>
    //     <h1>My Experience</h1>
    //   </div>
    //   <div className='flex flex-col items-center pt-8'>
    //     {experiences.map((experience, index) => (
    //       <Experience key={index} {...experience} />
    //     ))}
    //   </div>
    // </div>
    <div>
        <Navbar />
        <div className="flex justify-center text-white pt-10">
            <h1>Coming soon!</h1>
        </div>
    </div>
  );
}

export default Main;
