import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/Home/Main.jsx';
import ProjectsPage from './Pages/Projects/Main.jsx';
import ExperiencePage from './Pages/Experience/Main.jsx';
import Navbar from './Pages/Nav.jsx';
import './index.css';


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/experience" element={<ExperiencePage />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
