import { Link } from 'react-router-dom';
import './Nav.css';
const logo = require('../assets/final_logo.png');


function Navbar() {

  return (
    <nav className={`px-20 pt-8 flex items-center justify-between ${'bg-gray-800'} ${'text-white'}`}>
      <Link to="/">
        <div className="flex items-center">
          <img src={logo} style={{width: '80px' }} />
          <span className={`text-3xl font-bold ${'text-white'} ${'ml-1'}`}>Afraz Khan</span>
        </div>
      </Link>

      {/* Menu items */}
      <ul className="flex items-center text-xl gap-7">
        <li className="pl-10">
          <Link id='pjects' to="/projects" className={`${'text-gray-200'} ${'mr-6'}`}>Projects</Link>
        </li>
        <li className="pl-10">
          <Link to='/experience' id='pjects' className={`${'text-gray-200'} ${'mr-6'}`}>Experience</Link>
        </li>
        <li className="ml-10 flex flex-col" id='hyperLink'>
          {/* <Link to='/resume' className={`${'text-gray-200'} ${'mr-2'}`}>Resume</Link> */}
          <a href="https://drive.google.com/file/d/16b_ZFOKas9KOvTZWAUQB4DRzJxQsGFQt/view?usp=sharing" target="_blank" rel="noreferrer" className={`${'text-gray-200'} ${'mr-2'}`}>Resume</a>
          {/* <img src={hyperLink} style={{width: '15px', height: '15px', color: 'white', marginTop: '0.4rem'}} /> */}
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
