import React from 'react';
import Navbar from '../Nav.jsx';
import './Main.css';
import profilePic from '../../assets/profile_pic.jpg';
import insta from '../../assets/Instagram_Glyph_Gradient copy.png';
import gh from '../../assets/github-mark-white.png';
import li from '../../assets/LI-In-Bug.png';
import mail from '../../assets/icons8-mail-100.png';


function HomePage() {
  return (
    <div className="bg-gray-800">
      <Navbar />
      <main className="max-w-4xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0 flex flex-row items-center justify-center">
          <div className="flex flex-row items-center flex-1">
            <div>
              <h1 className={`text-xl ${'text-gray-300'}`}>
                Hey there!
              </h1>
              <h1 className={`mt-4 text-3xl font-bold ${'text-white'}`}>
                I'm Afraz,
              </h1>
              <h1 className={`mt-4 text-xl text-white`}>
                a fullstack developer and mobile app enthusiast with a passion for AI. With a strong background in modern development tools, I'm dedicated to crafting high-quality software solutions that empower users and drive innovation.
              </h1>
            </div>
            <div className='flex flex-col-reverse gap-7'>
              <div className="flex flex-row items-center mt-6 sm:mt-0 gap-6 pl-12">
                <a href="https://www.linkedin.com/in/afraz-khan-434903209/" target="_blank" rel="noreferrer">
                  <img src={li} alt="LinkedIn" className="w-8 h-7 mx-4 transition duration-350 ease-in-out transform hover:-translate-y-2" />
                </a>
                <a href="https://github.com/Afraz0Khan" target="_blank" rel="noreferrer">
                  <img src={gh} alt="GitHub" className="w-7.5 h-7 mx-4 transition duration-350 ease-in-out transform hover:-translate-y-2" />
                </a>
                <a href="mailto:afraz1@ualberta.ca" target="_blank" rel="noreferrer">
                  <img src={mail} alt="Email" className="w-8 h-7 mx-4 transition duration-350 ease-in-out transform hover:-translate-y-2" />
                </a>
                <a href="https://www.instagram.com/afrazz.k/" target="_blank" rel="noreferrer">
                  <img src={insta} alt="Instagram" className="w-7 h-7 mx-4 transition duration-350 ease-in-out transform hover:-translate-y-2" />
                </a>
              </div>
              {/* add a border in the picture below */}
              <img src={profilePic} alt="Profile Pic" className="ml-10 w-64 h-90 circle rounded-xl border border-gray-400 shadow-2xl" />
              </div>
            </div>
        </div>
      </main>
    </div>
  );
}

export default HomePage;
