import React from 'react';
import './Main.css';
import Logo from './Logos';


function Project(props) {
  return (
    <div className="max-w-4xl mx-auto px-4 py-3">
      <a href={props.link} target="_blank" rel="noreferrer"> 
        <div className="border border-gray-300 rounded-lg p-4 mb-4 bg-white shadow-2xl hover:shadow-4xl hover:scale-105 transition duration-300 opacity-90">
            
            <h2 className="text-lg font-medium mb-2">{props.name}</h2>
            <p className="text-gray-600">{props.description}</p>
            {/* <div className='flex flex-wrap gap-3'>
              {
                props.tech.map(tech => (
                  <Logo logo={tech} />
                ))
              }
            </div> */}
            <div className="flex justify-between items-center">
                <svg className="h-5 w-5 fill-current text-yellow-500" viewBox="0 0 20 20">
                    <path d="M10 1L12.2452 6.1772L17 7.43883L13.619 11.874L14.5291 17L10 14.3335L5.47093 17L6.38102 11.874L3 7.43883L7.75479 6.1772L10 1Z"/>
                </svg>
                <p className="text-gray-500 ml-2">{props.stars}</p>
            </div>
        </div>
        </a>
    </div>
  );
}

export default Project;
